.heat-map-toggle {
    display: flex;
    top: 50px;

    label {
        font-size: 12px;
        color: white;
    }

    // TODO add small switch styles to reusables

    .beewise-toggle .switch {
        height: 10px;
        width: 20px;
        &:after {
            width: 12px;
            height: 12px;
            top: -1px;
        }
    }

    .checkbox:checked + .switch::after {
        left: 10px !important;
    }
}

@import '~@beewise/theme/build/variables';
.heat-map-timeframes {
    display: flex;
    flex-direction: row;
    gap: 10px;
    top: unset;
    bottom: 32px;
    span {
        cursor: pointer;
        &.selected {
            background-color: $white;
            color: $black;
            border-radius: 6px;
            padding: 4px 8px;
        }
        color: $white;
    }
}

@import '~@beewise/theme/build/variables';
.heat-map-legend {
    display: flex;
    align-items: center;
    gap: 4px;
    top: 100px;
    .percentage-marks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 120px;
        font-size: 12px;
        color: $white;

        span {
            line-height: 1;
        }
    }

    .percentage-bar {
        width: 8px;
        height: 120px;
        border-radius: 2px;
        background: linear-gradient(
            180deg,
            #0fa446 0%,
            // Light green
            #22c55e 15%,
            // Green
            #facc15 40%,
            // Yellow
            #f97316 65%,
            // Orange
            #dc2626 85%,
            // Red
            #b91c1c 100% // Dark red
        );
    }
}

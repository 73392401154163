@import '~@beewise/theme/build/variables';

.ranches-search {
    display: flex;
    position: relative;
    margin-bottom: 13px;
    width: 100%;
    max-height: 36px;

    .text-field-root {
        width: 100%;
        position: relative;

        .text-field-container {
            background: $white;
            border: 1px solid $grey;
            border-radius: 32px;
            min-height: 30px;
            max-height: 36px;

            .text-field {
                font-size: 14px;
                color: $grey-5;
                border-radius: 32px;

                &--active {
                    color: $black;
                }
            }
        }

        .text-field-label--item {
            display: flex;
            gap: 8px;
            align-items: center;
            color: $grey-5;
        }
    }

    .clear-icon-wrapper {
        cursor: pointer;
        border-radius: 50%;
        border: 0;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        padding: 4px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $gray-50;

        .clear-icon {
            color: $white;
        }
    }

    // .select-field-root {
    //     width: 100%;

    //     &:hover {
    //         .select-field__control {
    //             background: $white;
    //         }

    //         .select-field__single-value {
    //             color: $grey-4;
    //         }

    //         .select-field-info {
    //             svg {
    //                 color: $grey-4;
    //             }
    //         }
    //     }

    //     .select-field__value-container {
    //         padding: 6px 12px;
    //         margin-left: 24px;
    //     }

    //     .select-field__control {
    //         background: $white;
    //         border: 1px solid $grey;
    //         border-radius: 32px;
    //         min-height: 30px;

    //         &:has(.select-field__input:focus) {
    //             border-color: $grey;
    //         }
    //     }

    //     .select-field__placeholder,
    //     .select-field__single-value {
    //         font-size: 14px;
    //         color: $grey-5;
    //     }

    //     &:has(.select-field__input:focus) {
    //         .select-field__placeholder {
    //             color: $grey-5;
    //         }
    //     }

    //     .select-field__indicators {
    //         &:before {
    //             border: 0;
    //         }
    //     }

    //     .select-field-info {
    //         margin: 0;
    //         top: 50%;
    //         transform: translate(0, -50%);
    //         left: 14px;

    //         svg {
    //             width: 16px;
    //             height: 16px;
    //             color: $grey-5;
    //         }
    //     }

    // .select-field__menu {
    //     border-radius: 12px;
    //     padding: 12px;

    //     .select-field__option {
    //         background: $white;
    //         color: $black;
    //         cursor: pointer;
    //         font-size: 12px;

    //         &.select-field__option--is-selected {
    //             color: $static-partition;
    //         }

    //         &:hover {
    //             background: $secondary-blue;
    //             color: $static-partition;
    //         }
    //     }
    // }
    // }
}

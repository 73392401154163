@import '~@beewise/theme/build/variables';

.ranch-info {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .bold {
        font-weight: 500;
    }

    .text {
        color: $text-secondary;
        font-size: 16px;
    }
}

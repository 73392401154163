@import '~@beewise/theme/build/variables';

.contacts-grid-container {
    width: 100%;
    padding: 20px;
    background-color: $white;

    .contacts-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h2 {
            font-size: 24px;
            font-weight: bold;
        }
    }

    .filters {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: space-between;

        .filter-selectors {
            display: flex;
            gap: 20px;
            width: 50%;

            .select-field-root {
                min-width: 250px;
            }
        }

        .search-wrapper {
            display: flex;
            align-items: center;
            border: 1px solid $grey-6;
            border-radius: 30px;
            padding: 0 14px;
            width: 300px;
            max-height: 40px;
            box-sizing: border-box;

            .search-icon-wrapper {
                margin-right: 8px;

                .search-icon {
                    color: $grey-6;
                    font-size: 16px;
                }
            }

            .clear-icon-wrapper {
                margin-left: 64px;
                cursor: pointer;
                background: none;
                border: none;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    opacity: 0.7;
                }

                .clear-icon {
                    color: $grey-6;
                    font-size: 16px;
                }
            }

            .text-field-container {
                border: 0;
            }
        }
    }

    .contacts-grid-table {
        height: calc(100vh - 200px);

        .searchable-grid-container {
            .grid-wrapper {
                padding: 0;

                .cell-column p {
                    font-weight: 100;
                }

                .MuiDataGrid-columnHeader {
                    background-color: #f9fafe;
                }
            }
        }
    }

    .searchable-grid {
        border: 0;
    }

    .role-badge {
        display: inline-block;
        padding: 4px 12px;
        border-radius: 15px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: $white;

        &.beekeeper {
            background-color: #fff5db;
            color: #c89619;
        }

        &.grower {
            background-color: rgba(231, 201, 251, 0.3);
            color: #9560b8;
        }

        &.installer {
            background-color: #e9f6ff;
            color: #157ab2;
        }

        &.ranch_manager {
            background-color: rgba(155, 208, 108, 0.2);
            color: #5c942b;
        }

        &.unknown-role {
            background-color: $grey-7;
            color: $gray-70;
        }
    }

    .grid-footer {
        display: flex;
        justify-content: center;
    }
}

@import '~@beewise/theme/build/variables';

.map-info {
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 12px;
    border-radius: 4px;
    background: $dark-black;
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 12px;

    &-block {
        display: flex;
        align-items: center;
        color: $white;
        font-size: 12px;
        line-height: 18px;

        &-span {
            width: 6px;
            height: 6px;
            border: 1px solid $white;
            border-radius: 50%;
            margin-right: 6px;
        }
    }
}

@import '~@beewise/theme/build/variables';

.general-info-with-reports {
    flex: 1.5;

    .ranch-header {
        display: flex;
        padding: 0;
        align-items: flex-start;
        gap: 30px;
        justify-content: space-between;
        width: 929px;

        .nav-section {
            display: flex;
            align-items: baseline;
            gap: 30px;
            margin-bottom: 30px;

            .select-field__single-value {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: $gray-100;
            }
        }
    }

    .ranch-reports {
        display: flex;
        flex-direction: row;
        gap: 26px;
    }

    .search-select-field {
        width: 300px;
    }

    .ranch-header-text {
        color: $capped-honey;
        font-size: 22px;
        font-weight: 600;
        width: 100%;
        display: contents;
    }

    @media (max-width: 1100px) {
        .ranch-reports {
            flex-direction: column;
            gap: 0px;
        }
    }
}
